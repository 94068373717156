<template class="app">
    <Header position="absolute"></Header>

    <landing-section />

    <!-- <motivation-section /> -->

    <!-- <summary-section /> -->

    <!-- <section class="wave-background">
        <img src="./assets/wave.svg">
    </section> -->

    <our-work-show />
    
    <references-section />

    <timeline-section />

    <contact-section />

    <footer class="footer">
        <div class="container">
            <span>
                &copy; FitPixels.
            </span>
        </div>
    </footer>

    <div class="manager_contact_info" v-if="contactModelShow">
        <div class="layout" @click="contactModelShow = false"></div>

        <div class="modal">
            <div class="heading">
                <p class="name">Martin Zach - Kontaktní osoba</p>
                <i class="fa-solid fa-xmark" @click="contactModelShow = false"></i>
            </div>
            <ul class="contacts">
                <li><a href="mailto:business@martinzach.cz" target="_blank"><i class="fa-solid fa-envelope"></i><b>business@martinzach.cz</b></a></li>
                <li><a href="tel:704338431" target="_blank"><i class="fa-solid fa-phone"></i>+420 <b>704 338 431</b></a></li>
                <li><i class="fa-solid fa-house"></i><b>Ladova 895, Mnichovice 251 64</b></li>
                <li><i class="fa-solid fa-passport"></i><b> IČO: 17396450</b></li>
            </ul>
        </div>
    </div>
</template>

<script>

import Header from "./components/Header";
import LandingSection from "./components/LandingSection";
// import SummarySection from "./components/SummarySection";
import ReferencesSection from "./components/ReferencesSection";
import OurWorkShow from "./components/OurWorkShow";
// import MotivationSection from "./components/MotivationSection.vue";
import TimelineSection from "./components/TimelineSection";
import ContactSection from "./components/ContactSection";
    
import "vueperslides/dist/vueperslides.css";

export default {
    name: 'App',
    // components: { Header, LandingSection, SummarySection, ReferencesSection, OurWorkShow, MotivationSection, TimelineSection, ContactSection },
    components: { Header, LandingSection, ReferencesSection, OurWorkShow, TimelineSection, ContactSection },
    data() {
        return {
            bookingPlatformLink: "https://tidycal.com/fitpixels/15-minutovy-meeting",
            contactModelShow: false
        }
    },
    methods: {
        scrollToElementWithId(scrollToElementId){
            console.log("scroll called");

            const summarySection = document.getElementById(scrollToElementId);

            console.log("scroll found ", summarySection);

            if(summarySection) {
                summarySection.scrollIntoView({ behavior: "smooth" });
            }
        }
    }
}

</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.app{
    display: inline-block;
   min-width: 0%;
   transform: translatez(0);
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 18px;
}

html{
    font-family: 'Inter', sans-serif;
}

body{
    overflow-x: hidden;
}

p, span{
    color: #ACACAC;
}

.btn{
    background: #616161;
    padding: 15px 30px;
    color: #fff !important;
    font-weight: 600;
    font-size: 20px;
    border-radius: 20px;

    display: flex;
    transition: .3s;
    text-decoration: none;
    justify-content: center;
    align-items: center;

    border: none;

    cursor: pointer;
}

.btn-primary{
    background: #8540E8;
    color: #fff !important;

    &:hover{
        background: darken(#8540E8, 10%);
    }
}

.btn-highlight{
    background: #5500E9;
    color: #fff !important;

    &:hover{
        background: darken(#5500E9, 10%);
    }
}

.btn-secondary{
    background: #fff;
    border: 4px solid #767676;
    font-weight: 700;
    color: #616161 !important;
    padding: 11px 26px;

    &:hover{
        border: 4px solid darken(#5500E9, 10%);
        background: darken(#5500E9, 10%);
        color: #fff !important;
    }
}

.text-highlight{
    color: #616161; // grey because in timeline section is animation which needs to start at gray
}

.container{
    max-width: 1440px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
}

.wave-background{
    width: 100%;

    img{
        width: 100%;
    }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.footer{
    padding: 20px 0;
    border-top: 2px solid #ACACAC;

    .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        gap: 15px;

        font-weight: 600;
        color: #767676;

        .contact_link{
            text-decoration: underline;
            cursor: pointer;
            transition: .2s;

            &:hover{
                color: #8540E8;
            }
        }

        a{
            color: inherit;
        }

        span{
            text-align: center;
        }
    }
}

.manager_contact_info{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 999;
    
    .layout{
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        
        position: absolute;
        top: 0;
        left: 0;
    }
    
    .modal{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        padding: 20px;
        border-radius: 20px;

        background: #fff;

        width: calc(100% - 40px);
        max-width: 500px;

        display: flex;
        flex-direction: column;
        gap: 15px;

        .heading{
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3{
                font-size: 25px;
            }

            i{
                font-size: 25px;
                color: #ACACAC;
                cursor: pointer
            }
        }
        

        .name{
            font-weight: bold;
            font-size: 23px;
            color: #000;
        }

        .contacts{
            list-style: none;

            display: flex;
            flex-direction: column;
            gap: 20px;

            color: #ACACAC;

            li{
                display: flex;
                gap: 10px;

                a{
                    display: flex;
                    gap: 10px;

                    color: #ACACAC;

                    transition: .2s;
                    text-decoration: none;

                    &:hover,
                    &:active{
                        color: #8540E8;
                    }
                }
            }
        }
    }
}

</style>