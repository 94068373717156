<template>
    <section class="references-section" id="references-sectionn">
        <div class="container">
            <div class="section-header">
                <h1>Ohlasy na spolupráci</h1>
                <span>(komunikace a management - Martin Zach)</span>
            </div>
            
            <div class="cards">
                <div class="card" v-for="(reference, i) in references" :key="i">
                    <img :src="reference.img" alt="" class="profile-img">
                    <h3 class="card-title">{{ reference.title }}</h3>
                    <span class="card-subtitle">{{ reference.subtitle }}</span>
                    <i class="fa-solid fa-quote-left"></i>
                    <span v-html="reference.content" class="card-content"></span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                    references: [
                    {
                        // eslint-disable-next-line no-undef
                        img: require("@/assets/profile-images/zuzana-walisko.webp"),
                        title: "Zuzana Walisko",
                        subtitle: "Vedoucí I Code the Future",
                        content: "“Spolupráce probíhala skvěle, oceňuji jejich profesionální přístup, originální nápady, zodpovědný přístup a také bezvadné dodržení termínů.<br><br>Díky FitPixels se z pouhých napadů stane opravdová realita.”"
                    },
                    {
                        // eslint-disable-next-line no-undef
                        img: require("@/assets/profile-images/marek-novak.webp"),
                        title: "Marek Novák",
                        subtitle: "CEO Hodinky teprve",
                        content: "“S vedením se mi pracovalo skvěle. Nejen že dodržovali termíny, přicházeli s novými nápady, ale dokázali moje představy přenést do funkční podoby.<br><br>Spolupráce s nim byla na velmi profesionální úrovni.”"
                    },
                    {
                        // eslint-disable-next-line no-undef
                        img: require("@/assets/profile-images/david-semanisin.webp"),
                        title: "David Semanišin",
                        subtitle: "Herní vývojář",
                        content: "“S FitPixels jsem pracoval na projektech a hodně jsem si z toho vzal.”"
                    },
                ]
            }
        },
    }
</script>

<style lang="scss" scoped>

.references-section{
    // padding: 50px 0;
    padding-bottom: 50px;
    padding-top: 20px;

    .container{
        max-width: 1200px;

        display: flex;
        flex-direction: column;
        gap: 20px;

        h1{
            font-size: 60px;
            font-weight: 700;
            text-align: center;
        }

        .section-header{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;

            text-align: center
        }

        .cards{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 20px;

            .card{
                background: #fff;
                box-shadow: 15px 15px 35px rgba(96, 96, 96, 0.35);
                padding: 20px;
                border-radius: 15px;
    
                width: 100%;
    
                display: flex;
                flex-direction: column;
                gap: 15px;
                align-items: center;
    
                text-align: center;
    
                .profile-img{
                    border-radius: 50%;
                    width: 160px;
                    height: 160px;
    
                    object-fit: cover;
                    object-position: center;
                }
    
                .card-title{
                    font-size: 30px;
                    font-weight: 700;
                }
    
                .card-subtitle{
                    font-weight: 600;
                }
    
                i{
                    font-size: 70px;
                    color: #EDEDED;
    
                    animation: color-highlight 10s infinite;
    
                }
    
                &:nth-child(2) i{ animation-delay: 3s; }
    
                &:nth-child(3) i{ animation-delay: 6s; }
    
                @keyframes color-highlight{
                    0%{
                        color: #EDEDED;
                    }
                
                    10%{
                        color: #712BEC;
                    }
    
                    30%{
                        color: #712BEC;
                    }
    
                    40%{
                        color: #EDEDED;
                    }
    
                    100%{
                        color: #EDEDED;
                    }
                }
    
                .card-content{
                    font-weight: 600;
                    font-size: 20px;
                }
            }
        }
    }
}

@media only screen and (max-width: 900px){
    .references-section{
        .container{
            max-width: 700px;

            h1{
                font-size: 40px !important;
            }

            .cards{
                flex-direction: column;
            }

            .card{
                background: #fff;
                box-shadow: 15px 15px 35px rgba(96, 96, 96, 0.35);
                padding: 20px;
                border-radius: 15px;

                width: 100%;

                display: flex;
                flex-direction: column;
                gap: 15px;
                align-items: center;

                text-align: center;

                .profile-img{
                    border-radius: 50%;
                    width: 160px;
                    height: 160px;
                }

                .card-title{
                    font-size: 30px;
                    font-weight: 700;
                }

                .card-subtitle{
                    font-weight: 600;
                }

                i{
                    font-size: 70px;
                    color: #EDEDED;

                    animation: color-highlight 10s infinite;

                }

                &:nth-child(2) i{ animation-delay: 3s; }

                &:nth-child(3) i{ animation-delay: 6s; }

                @keyframes color-highlight{
                    0%{
                        color: #EDEDED;
                    }
                
                    10%{
                        color: #712BEC;
                    }

                    30%{
                        color: #712BEC;
                    }

                    40%{
                        color: #EDEDED;
                    }

                    100%{
                        color: #EDEDED;
                    }
                }
            }
        }
    }
}

</style>