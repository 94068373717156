<template>
    <transition name="slide">
        <div v-if="visible" class="flash-message" :class="{ success: type === 'success', danger: type === 'danger'}">
            {{ message }}
        </div>
    </transition>
</template>

<script>
    export default {
        props: ["type", "message"],
        data() {
            return {
                visible: false
            }
        },
        methods: {
            showMessage(){
                if(this.message != ""){
                    this.visible = true;
            
                    setTimeout(() => {
                        this.visible = false;
                    }, 5000);
                }
            }
        },
        mounted(){
            this.showMessage();
        },
        watch: {
            message(){
                this.showMessage();
            }
        }
    }
</script>

<style lang="scss" scoped>

.flash-message{
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%);

    padding: 15px 30px;
    border-radius: 20px;

    font-weight: 600;
    z-index: 9999;
    text-align: center;
}

.success{
    border: 2px solid #2dd36f;
    background: lighten(#2dd36f, 48%);
    color: #2dd36f;
}

.danger{
    border: 2px solid #eb445a;
    background: lighten(#eb445a, 40%);
}

.slide-enter-active, .slide-leave-active {
    transition: all 0.5s ease;
    user-select: none;
}

.slide-enter-from, .slide-leave-to {
    transform: translate(-50%, 100%);
    bottom: 0;
    opacity: 0;
}

</style>