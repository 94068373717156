<template>
    <section class="motivation-section" id="our-work-show">
        <h1 class="section-header">Naše práce</h1>

        <div class="container">
            <div v-for="(reference, index) in references" :key="index" class="reference-item">
                <img :src="reference.image" class="col-1">

                <span class="col-2">
                    <h1>{{ reference.title }}</h1>
                    <p>{{ reference.description }}</p>

                    <span class="tags">
                        <div class="tag" v-for="(tag, tagIndex) in reference.tags" :key="tagIndex">
                            <i :class="{
                                'fa-solid': true,
                                'fa-pen-nib': tag === 'Design',
                                'fa-code': tag === 'Webový vývoj',
                                'fa-brands fa-wordpress-simple': tag === 'WordPress',
                                'fa-keyboard': tag === 'Copywriting',
                                'fa-chart-simple': tag === 'Google Analytics',
                                'fa-graduation-cap': tag === 'Tvorba vzdělávacího obsahu',
                                'fa-crown': tag === 'Aplikace na míru'}"></i>&nbsp;&nbsp;
                            {{ tag }}
                        </div>
                    </span>
                </span>
            </div>
        </div>
    </section>
</template>
<script>

export default{
    data(){
        return{
            references: [
                {
                    title: "Kickbox Aplikace",
                    description: "Aplikace poskytuje možnost navrhnout vlastní trénink, cvičit podle automaticky složeného nebo profesionálně poskládaného tréninku.",
                    tags: ["Design", "Webový vývoj", "Aplikace na míru"],
                    // eslint-disable-next-line no-undef
                    image: require("@/assets/kickbox.webp"),
                },
                // {
                //     title: "Webfy Software",
                //     tags: ["Design", "Animace a 3d modeling", "Webový vývoj"],
                //     // eslint-disable-next-line no-undef
                //     image: require("@/assets/webfy.webp"),
                //     arrowColor: "white"
                // },
                {
                    title: "I code the future",
                    description: "Hra člověče nezlob se byla vytvořena ve spolupráci se SŠAI. Cílem bylo jednoduchým způsobem naučit děti základy programování hrou.",
                    tags: ["Design", "Aplikace na míru", "Google Analytics", "Tvorba vzdělávacího obsahu"],
                    // eslint-disable-next-line no-undef
                    image: require("@/assets/clovece.webp"),
                },
                // {
                //     title: "Redakční systém (anonym)",
                //     tags: ["Web-design", "Vývoj na míru", "Responzivita", "Optimalizace databáze"],
                //     // eslint-disable-next-line no-undef
                //     image: require("@/assets/adminpanel.webp"),
                //     arrowColor: "white"
                // },
                // {
                //     title: "School Michael",
                //     tags: ["Wordpress web", "Responzivita"],
                //     // eslint-disable-next-line no-undef
                //     image: require("@/assets/schoolmichael.webp"),
                //     arrowColor: "black"
                // },
                {
                    title: "Cuty Brýle",
                    description: "Dropshipping e-shop na speciální brýle proti modrému světlu. Kladli jsme především důraz na estetiku a celkový dojem webu.",
                    tags: ["Design", "WordPress", "Copywriting"],
                    // eslint-disable-next-line no-undef
                    image: require("@/assets/cuty.webp"),
                }
            ]
        }
    }
}

</script>
<style lang="scss" scoped scss>

.motivation-section{
    padding: 60px 0;

    .section-header{
        text-align: center;
        font-size: 50px;
        font-weight: 700;
        color: #000;
        margin-bottom: 20px;
    }

    .container{
        display: flex;
        gap: 30px;
    }

    .reference-item{
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 31%;

        img{
            object-fit: cover;
            border-radius: 10px;
        }

        .col-2{
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: center;

            h1{
                color: #000;
                font-size: 25px;
            }

            .tags{
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px;

                .tag{
                    color: #fff;
                    background: #712BEC;
                    padding: 5px 10px;
                    border-radius: 5px;
                    display: inline-block;
                }
            }
        }
    }
}

@media screen and (max-width: 770px) {
    .motivation-section .container{
        flex-direction: column;
        gap: 50px;

        .reference-item{
            width: 100%;
        }
    }
}

</style>
