<template>
    <section class="timeline-section">
        <div class="container timeline-pc">
            <div class="top-dot">
                <div class="starting-dot-bg">
                    <div class="starting-dot"></div>
                </div>
            </div>

            <div class="main-container" id="main-container">
                <div class="left-section">
                    <div class="card-wrapper" v-for="i in 2" :key="i">
                        <div class="card card-timeline card-timeline-pc">
                            <h3 class="card-title">{{ timelineItems[i === 1 ? 0 : 2].title }}</h3>
                            <!-- <p class="card-content">{{ timelineItems[i === 1 ? 0 : 2].content }}</p> -->
                            <p class="card-content" v-html="timelineItems[i === 1 ? 0 : 2].content"></p>
                            
                            <div class="line-from-card-bg"></div>
                            <div class="line-from-card"></div>

                            <svg class="rounded-line-from-card-bg">
                                <path class="line" d="M 35 0 A 35 35 0 0 1 0 35" />
                            </svg>

                            <svg class="rounded-line-from-card">
                                <path class="line line-anim" d="M 35 0 A 35 35 0 0 1 0 35" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div class="center-line" id="center-line">
                    <div class="center-line-fill-bg"></div>
                    <div class="center-line-fill"></div>
                </div>

                <div class="right-section" id="right-section">
                    <div class="card-wrapper" v-for="i in 2" :key="i">
                        <div class="card card-timeline card-timeline-pc">
                            <h3 class="card-title">{{ timelineItems[i === 1 ? 1 : 3].title }}</h3>
                            <p class="card-content" v-html="timelineItems[i === 1 ? 1 : 3].content"></p>

                            <div class="line-from-card-bg"></div>
                            <div class="line-from-card"></div>

                            <svg class="rounded-line-from-card-bg">
                                <path class="line" d="M 5 5 A 35 35 0 0 0 35 35" />
                            </svg>

                            <svg class="rounded-line-from-card">
                                <path class="line line-anim" d="M 5 5 A 35 35 0 0 0 35 35" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container timeline-mobile">
            <div class="top-dot">
                <div class="starting-dot-bg">
                    <div class="starting-dot"></div>
                </div>
            </div>

            <div class="line-plus-card" v-for="(item, index) in timelineItems" :key="index">
                <div class="center-line-mobile-bg">
                    <div class="center-line-mobile"></div>
                </div>
                <div class="card-wrapper">
                    <div class="card card-timeline card-timeline-mobile">
                        <h3 class="card-title">{{ item.title }}</h3>
                        <p class="card-content" v-html="item.content"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { gsap } from "gsap";
    import ScrollTrigger from "gsap/ScrollTrigger";
    import { CSSRulePlugin } from "gsap/CSSRulePlugin";

    export default {
        data() {
            return {
                timelineItems: [
                    {
                        title: "1. Úvodní schůze",
                        content: `Naplánujte si <a href='https://tidycal.com/fitpixels/15-minutovy-meeting' target='_blank' class='link text-highlight'>online schůzi</a> hned teď nebo nám napiště skrze náš <span class="text-highlight">formulář</span>.`
                    },
                    {
                        title: "2. Konzultace Vašich požadavků a očekávání",
                        content: `Probereme jakým způsobem bude probíhat vývoj, a kdy bude Váš web <span class="text-highlight">hotový k použití</span>.`
                    },
                    {
                        title: "Návrh a vývoj",
                        content: `V průběhu vývoje budeme brát ohled na Vaši <span class="text-highlight">zpětnou vazbu</span>.`
                    },
                    {
                        title: "Dokončení projektu",
                        content: `Projekt odevzdáme vždy na <span class="text-highlight">čas</span> a tak, abyste byli spokojeni.`
                    },
                ]
            }
        },
        mounted(){
            this.heightChanged();
            // window.addEventListener("resize", this.heightChanged);

            gsap.registerPlugin(ScrollTrigger);
            gsap.registerPlugin(CSSRulePlugin);

            const startingDots = document.querySelectorAll(".starting-dot");
            startingDots.forEach(startingDot => {
                gsap.from(startingDot, {
                    scrollTrigger: {
                        trigger: startingDot,
                        start: "center bottom",
                        end: "center 80%",
                        scrub: true,
                        // markers: true,
                        ease: "none"
                    },
                    height: 0,
                    width: 0,
                });
            });

            gsap.to(".center-line-fill", {
                scrollTrigger: {
                    trigger: ".center-line",
                    start: "top 80%",
                    end: "bottom 80%",
                    scrub: true,
                    // markers: true,
                    ease: "power0.easeNone"
                },
                height: "100%"
            });
            
            const roundedLinesFromCards = document.querySelectorAll(".line-anim");
            roundedLinesFromCards.forEach(roundedLineFromCard => {
                gsap.to(roundedLineFromCard, {
                    scrollTrigger: {
                        trigger: roundedLineFromCard,
                        start: "top 80%",
                        end: `bottom 70%`,
                        scrub: true,
                        // markers: true,
                        ease: "none"
                    },
                    strokeDashoffset: 147
                });
            });

            const linesFromCards = document.querySelectorAll(".line-from-card");
            linesFromCards.forEach(lineFromCard => {
                gsap.from(lineFromCard, {
                    scrollTrigger: {
                        trigger: lineFromCard,
                        start: "bottom 70%",
                        end: "bottom 50%",
                        scrub: true,
                        ease: "none",
                        // markers: true,
                    },
                    width: "0",
                });

                gsap.to(lineFromCard, {
                    scrollTrigger: {
                        trigger: lineFromCard,
                        start: "bottom 48%",
                        end: "bottom 50%",
                        scrub: true,
                        ease: "none",
                        // markers: true,
                    },
                    borderRadius: "0",
                });
            });

            const cardsTimelinePc = document.querySelectorAll(".card-timeline-pc");
            cardsTimelinePc.forEach(cardTimeline => {
                gsap.to(cardTimeline, {
                    scrollTrigger: {
                        trigger: cardTimeline,
                        start: "center 50%",
                        end: "center 30%",
                        scrub: true,
                    },
                    background: "#fff",
                    color: "#000",
                    border: "7px solid #712BEC"
                });
            });

            const textHighlightTimelinePc = document.querySelectorAll(".text-highlight");
            textHighlightTimelinePc.forEach(textHighlight => {
                gsap.to(textHighlight, {
                    scrollTrigger: {
                        trigger: textHighlight,
                        start: "center 50%",
                        end: "center 30%",
                        scrub: true,
                    },
                    color: "#5500E9",
                });
            });
            
            const centerLinesMobile = document.querySelectorAll(".center-line-mobile");
            centerLinesMobile.forEach(centerLineMobile => {
                gsap.to(centerLineMobile, {
                    scrollTrigger: {
                        trigger: centerLineMobile,
                        start: "start 80%",
                        end: "bottom 60%",
                        scrub: true,
                        // markers: true
                    },
                    height: "105%"
                })
            });

            const cardsTimelineMobile = document.querySelectorAll(".card-timeline-mobile");
            cardsTimelineMobile.forEach(cardTimeline => {
                gsap.to(cardTimeline, {
                    scrollTrigger: {
                        trigger: cardTimeline,
                        start: "top 65%",
                        end: "top 55%",
                        scrub: true,
                    },
                    background: "#fff",
                    color: "#000",
                    border: "7px solid #712BEC"
                });
            });
        },
        methods: {
            heightChanged(){
                console.log("height of brower changed");
                
                const mainContainer = document.getElementById("main-container");
                const centerLine = document.getElementById("center-line");
                
                const cardWrappers = document.querySelectorAll("#right-section .card-wrapper");
                const lastCardWrapper = cardWrappers[cardWrappers.length - 1];
    
                centerLine.style.height = `${mainContainer.offsetHeight - (lastCardWrapper.offsetHeight / 2) + (centerLine.offsetWidth / 2) - 30}px`;
            }
        }
    }
</script>

<style lang="scss">

.link{
    color: #7E7E7E !important;

    &:hover,
    &:focus{
        color: #712BEC;
    }
}

</style>

<style lang="scss" scoped>

.timeline-section{
    padding: 50px 0;

    .top-dot{
        width: 100%;
        display: flex;
        justify-content: center;

        .starting-dot,
        .starting-dot-bg{
            width: 70px;
            height: 70px;
            background: #712BEC;

            border-radius: 50%;
        }
        .starting-dot-bg{
            position: relative;

            background: #D9D9D9;

            .starting-dot{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .main-container{
        width: 100%;
        display: flex;
        justify-content: space-between;

        .left-section,
        .right-section{
            flex-grow: 1;
            width: 100%;
            padding-top: 100px;

            display: flex;
            flex-direction: column;
            gap: 100px;
        }

        .center-line{
            width: 10px;
            flex-shrink: 0;
            background: #D9D9D9;

            position: relative;

            z-index: 1;
            border-radius: 0 0 5px 5px;

            .center-line-fill{
                position: absolute;
                width: 100%;
                height: 0%;
                background: #712BEC;
                border-radius: 0 0 5px 5px;
            }
        }

        .card-wrapper{
            display: flex;
        }

        .left-section .card-wrapper{
            justify-content: flex-start;

            .rounded-line-from-card,
            .rounded-line-from-card-bg{
                right: 0;
                transform: translate(10px, calc(-100% + 5px));
            }

            .line-from-card,
            .line-from-card-bg{
                right: 24px;
            }
        }

        .right-section{
            margin-top: 150px;
            
            .card-wrapper{
                justify-content: flex-end;
                
                .rounded-line-from-card,
                .rounded-line-from-card-bg{
                    left: 0;
                    transform: translate(-10px, calc(-100% + 5px));
                }

                .line-from-card,
                .line-from-card-bg{
                    left: 24px;
                }

                &:last-child{
                    height: 50%;
                    overflow: visible;
                }
            }
        }
    }

    .card-wrapper{
        position: relative;

        .card{
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            background: #D9D9D9;
            color: #7E7E7E;
            border: 7px solid #D9D9D9;
            border-radius: 15px;
            padding: 25px;
            width: 80%;

            .card-title{
                font-size:45px;
            }

            .card-content{
                font-weight: 500;
            }

            @keyframes dash{
                to{
                    stroke-dashoffset: 0;
                }
            }

            .rounded-line-from-card,
            .rounded-line-from-card-bg{

                position: absolute;
                top: 50%;

                width: 40px;
                height: 40px;

                .line {
                    stroke-width: 10;
                    stroke-linecap: round;
                    fill: none;
                    stroke-dasharray: 200;
                    stroke-dashoffset: 200;
                }
            }
            
            .rounded-line-from-card-bg{
                .line{
                    stroke: #D9D9D9;
                    stroke-dashoffset: 0;
                }
            }
            
            .rounded-line-from-card{
                .line{
                    stroke: #712BEC;
                    // animation: dash 10s linear forwards;
                }
            }

            .line-from-card,
            .line-from-card-bg{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: calc(20% - 23.5px);
                height: 10px;
                background: #712BEC;
            }

            .line-from-card{
                border-radius: 5px 5px 5px 5px;
            }

            .line-from-card-bg{
                background: #D9D9D9;
            }
        }

        .card-timeline-mobile{
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        // .card.active{
        //     background: #fff !important;
        //     color: #000 !important;
        //     border: 7px solid #712BEC !important;
        // }

        // .quarter-ring{
        //     position: absolute;
        //     background: blue;
        //     width: 50px;
        //     height:
        // }
    }

    .timeline-mobile{
        display: none;

        flex-direction: column;
        align-items: strech;

        .line-plus-card{
            display: flex;
            flex-direction: column;
            align-items: center;

            .center-line-mobile-bg{
                width: 10px;
                height: 50px;
                background: #D9D9D9;

                .center-line-mobile{
                    width: 100%;
                    height: 0;
                    background: #712BEC;
                    border-radius: 0 0 5px 5px;
                }
            }
    
            .card-wrapper{
                width: 100%;

                .card{
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 1300px){
    .card-title{
        font-size: 30px !important;
    }
}

@media only screen and (max-width: 800px){
    .timeline-pc{
        display: none;
    }

    .timeline-mobile{
        display: flex !important;
    }
}

</style>