<template>
    <div class="main-header" :class="{ 'absolute-position': position == 'absolute'}">
        <div class="container">
            <page-logo />
    
            <!-- <a @click="this.$parent.scrollToElementWithId('contact-section')" class="nav-item btn btn-primary active">
                Kontakt
            </a> -->
        </div>
    </div>
</template>

<script>
    import PageLogo from "../components/PageLogo";

    export default {
        components: { PageLogo },
        props: ["position"]
    }
</script>

<style lang="scss" scoped>

.absolute-position{
    position: absolute;
}

.main-header{
    width: 100%;
    padding: 30px 0;
    z-index: 100;

    .container{
        display: flex;
        // justify-content: space-between;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 800px){
    .logo{
        img{
            width: 40px !important;
        }

        span{
            font-size: 30px !important;
        }
    }

    .btn{
        font-size: 15px;
    }
}

</style>